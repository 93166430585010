<template>
  <div style="color:#ff0000;text-align: center;">
    <h3>请求报错</h3>
    <p>{{ msg }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: this.$route.params.msg || "页面报错，联系管理员！"
    };
  }
};
</script>
